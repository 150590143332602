<template>
    <div>
        <div class="g-tile-row">
            <div class="g-tile">
                <div class="num">{{stat.thisYearNum}}</div>
                <div class="text">本年在检票数</div>
            </div>
            <div class="g-tile">
                <div class="num">{{stat.thisMonthNum}}</div>
                <div class="text">本月在检票数</div>
            </div>
            <div class="g-tile">
                <div class="num">{{stat.todayNum}}</div>
                <div class="text">今日在检票数</div>
            </div>
        </div>
        
        <div class="filter-line2">
            <van-dropdown-menu>
                <van-dropdown-item v-model="filter.errorTypes" :title="filter.errorTypes? null : '故障类别'" :options="filter.errorTypesOpts" @change="filterData"></van-dropdown-item>
            </van-dropdown-menu>

            <div class="my-flex-row">
                <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">本月</div>
                <div class="right-item" @click="definedDateFilter" :class="{active: tabIndex == 3}">
                    <span class="right-item-text" style="">{{calendarOpt.text}}</span>
                    <img style="width: 14px;" src="../../assets/date.png" alt="">
                </div>
            </div>
        </div>
        
        <div>
            <van-search v-model="searchVal" label="设备查询" placeholder="请输入设备完整编码" maxlength="30" @search="onSearch"></van-search>
        </div>

        <div class="rect-box" style="margin: 0;padding-top: 0;">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">                
                <div v-for="(error, i) in list" :key="i" class="check-item round-gray-box">
                    <div class="flex-cont">
                        <div>
                            <div class="text1">{{error.level}}类故障<span class="text2">{{error.equipCode}}</span></div>                        
                            <div>{{error.time}}</div>
                        </div>
                        <div>{{error.worker_name}}</div>
                    </div>
                    <div>{{error.desc}}</div>
                </div>
            </van-list>
        </div>
        
        <van-calendar v-model="calendarOpt.showCalendar" :max-date="calendarOpt.maxDate" :min-date="calendarOpt.minDate" @confirm="onCalendarConfirm"
         type="range" :lazy-render="false"></van-calendar>
    </div>
</template>

<script>
import {formatDate} from '@/utils/common'
import {mapGetters} from 'vuex'
import {debugErrorStat, debugErrorQuery} from '@/axios/device'
export default {
    data(){
        return {
            loading: false,
            finished: false,
            tabIndex: 1,
            searchVal: "",
            calendarOpt: {
                maxDate: new Date(),
                minDate: new Date((new Date().getFullYear() - 1) + '/01/01'),
                showCalendar: false,
                text: "选择时间段",
                startDate: null,
                endDate: null
            },
            filter: {
                errorTypes: null,
                errorTypesOpts: [{text: '全部', value: null},{text: 'A类故障', value: 'A'},
                {text: 'B类故障', value: 'B'},{text: 'C类故障', value: 'C'},{text: '其他', value: 'other'}]
            },
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            list: [],
            stat: {}
        }
    },
    computed: {
        ...mapGetters(['equipInfo']),
        dateText(){
            return formatDate(this.calendarOpt.date)
        }
    },
    watch: {
        tabIndex: function(){
            this.getErrorList(true)
        }
    },
    created(){ 
        this.getErrorStat()
        this.getErrorList()
    },
    methods: {   
        onLoad(){
            this.page.pageNo++
            this.getErrorList()
        },
        definedDateFilter(){
            this.calendarOpt.showCalendar = true
            this.tabIndex = 3
        },     
        onCalendarConfirm(_date){       
            const [start, end] = _date                 
            this.calendarOpt.showCalendar = false
            this.calendarOpt.startDate = formatDate(start)
            this.calendarOpt.endDate = formatDate(end)
            this.calendarOpt.text = formatDate(start, "month") + " - " + formatDate(end, "month")
            this.getErrorList(true)
        },
        filterData(){
            this.getErrorList(true)
        },
        getErrorStat(){
            debugErrorStat({
                date: formatDate(new Date()),
                equipType: this.equipInfo.equipType
            }).then(res => {
                this.stat = res.content
            })
        },
        getErrorList(_reset){
            let _params = {}
            if(this.tabIndex == 1){
                _params.beginDate = formatDate(new Date())
                _params.endDate = formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
            }else{
                if(!this.calendarOpt.startDate){
                    return
                }
                _params.beginDate = this.calendarOpt.startDate
                _params.endDate = this.calendarOpt.endDate
            }            
            if(_reset){
                this.page.pageNo = 1
                this.page.total = 0
                this.list = []
            }
            _params.equipType = this.equipInfo.equipType
            _params.errorTypes = this.filter.errorTypes
            _params.pageNo = this.page.pageNo
            _params.pageSize = this.page.pageSize
            _params.equipCode = this.searchVal

            debugErrorQuery(_params).then(res => {
                this.list = this.list.concat(res.content.list || [])
                this.page.total = res.content.totalRecords
                this.finished = this.list.length >= this.page.total
            }).finally(()=>{                
                this.loading = false
            })
        },
        onSearch(_val){
            this.getErrorList(true)
        }
    }
}
</script>

<style lang="scss" scoped>
    .filter-line2{
        display: flex;
        justify-content: space-between;
        margin: 0 .9375rem;
    }
    .g-tile-row{
        margin: 20px 0;
    }
    .my-flex-row{
        display: flex;
        margin: 10px;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        .filter-botton{
            font-size: 12px;
            padding: .3rem .5rem;
            margin: 0 .5rem;
        }
    }
    .list-item-box{
        margin: .416667rem .625rem;
        background: #f7f7f7;
        border-radius: 5px;
        padding: .416667rem .625rem;

        .list-item-box__header{
            display: flex;
            align-items: center;
            >.text2{
                flex-grow: 0;
            }
            >.text3{
                font-size: 20px;
            }
        }
        .grow-text{
            flex-grow: 1;
            text-align: left;
            margin-left: 5px;
        }
    }
    .list-item-box__content{
        display: flex;
        justify-content: space-between;
        margin: 5px 0 0 33px;
    }
    .check-item{
        text-align: left;
        padding: 15px;
        margin-bottom: 8px;
        .flex-cont{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
        }
        .text1{
            font-size: 17px;
            color: #333333;
            padding-bottom: 5px;
        }
        .text2{
            font-size: 14px;
            color: #595959;
            margin-left: 15px;
        }
    }
    .my-flex-row{
        display: flex;
        font-size: 12px;
        align-items: center;
        .filter-botton{
            font-size: 12px;
        }
        >.right-item{
            flex-grow: 1;
            text-align: right;
        }
    }

    .right-item{
        .right-item-text{
            vertical-align: top;
            color:#bfbfbf;
            margin-right: 5px;
            line-height: 18px;
        }
        &.active{
            .right-item-text{
                background: linear-gradient(135deg, #73b9fc 0%, #3f83f8 100%);
                color: #fff;
                border-radius: 22px;
                padding: .5rem 1.25rem;
            }
        }
    }
</style>